/**
 *
 * @param {string} parameterName
 */
function findGetParameter(parameterName) {
    if (window) {
        var result = null,
            tmp = [];
        window.location.search
            .substr(1)
            .split("&")
            .forEach(function (item) {
                tmp = item.split("=");
                if (tmp[0] === parameterName)
                    result = decodeURIComponent(tmp[1]);
            });
        return result;
    }
    return undefined;
}

export { findGetParameter };
